import { IconButton, Card, Typography, Stack, Avatar, Box, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import { Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getBanner } from "../../../../redux/banner";
import ModalBanner from "../Modal/ModalBanner";

const columns = [
  { Header: "preview", accessor: "preview", width: "15%", align: "left" },
  { Header: "label", accessor: "label", width: "15%", align: "left" },
  { Header: "status", accessor: "status", align: "center" },
  { Header: "countdown", accessor: "countdown", align: "center" },
  { Header: "start date", accessor: "startDate", align: "center" },
  { Header: "end date", accessor: "endDate", align: "center" },
  { Header: "action", accessor: "action", align: "center" },
];
const TabBanner = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.shop.shopId);
  const banners = useSelector((state) => state.banner.list);
  const [active, setActive] = useState(false);
  const [bannerIndex, setBannerIndex] = useState(0);

  useEffect(() => {
    dispatch(getBanner(shopId)).then((res) => setLoading(false));
  }, [shopId]);

  const handleCloseModal = useCallback(() => {
    setActive(false);
  }, []);

  const handleActiveModal = useCallback(
    (index) => () => {
      setActive(true);
      setBannerIndex(index);
    },
    []
  );

  const checkCountdownStatus = useCallback((use_countdown, countdown) => {
    if (use_countdown == 1) {
      if (countdown.type === "fixed" && countdown.time_out_action === "loop") {
        return ["success", "Active"];
      }
      if (Date.now() > countdown.countdown_time) {
        return ["warning", "Expired"];
      }
      return ["success", "Active"];
    } else {
      return ["light", "Inactive"];
    }
  });

  const rows = banners.map(
    ({ background_color, name, condition, status, use_countdown, countdown }, index) => {
      const countdownStatus = checkCountdownStatus(use_countdown, countdown);
      return {
        preview: (
          <div
            style={{
              width: "100px",
              height: "30px",
              display: "flex",
              alignContent: "center",
              backgroundColor: `${background_color}`,
            }}
          ></div>
        ),
        label: (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{name}</Typography>
          </Stack>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={status ? "active" : "deactivate"}
              color={status ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        countdown: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={countdownStatus[1]}
              color={countdownStatus[0]}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        startDate: <Typography>{condition.starts_at}</Typography>,
        endDate: <Typography>{condition.ends_at}</Typography>,
        action: (
          <IconButton color="info" onClick={handleActiveModal(index)}>
            <Visibility />
          </IconButton>
        ),
      };
    }
  );

  return (
    <Card sx={{ marginTop: 3 }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Typography variant="h6" color="whitesmoke">
          Banners
        </Typography>
      </MDBox>
      <MDBox pt={3}>
        {loading ? (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="info" />
          </Box>
        ) : banners.length === 0 ? (
          <Typography>{`Don't have any label`}</Typography>
        ) : (
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          ></DataTable>
        )}
      </MDBox>
      <ModalBanner active={active} onClose={handleCloseModal} dataIndex={bannerIndex} />
    </Card>
  );
};

export default TabBanner;
