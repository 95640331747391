import {
  IconButton,
  Card,
  Typography,
  Stack,
  Avatar,
  Box,
  CircularProgress,
  badgeClasses,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import { Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getTrustBadge } from "../../../../redux/trustBadge";
import ModaltrustBadge from "../Modal/ModalTrustBadge";

const columns = [
  { Header: "preview", accessor: "preview", width: "25%", align: "left" },
  { Header: "label", accessor: "label", width: "15%", align: "left" },
  { Header: "status", accessor: "status", align: "center" },
  { Header: "position type", accessor: "positionType", align: "center" },
  { Header: "start date", accessor: "startDate", align: "center" },
  { Header: "end date", accessor: "endDate", align: "center" },
  { Header: "action", accessor: "action", align: "center" },
];
const TabTrustBadge = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.shop.shopId);
  const trustBadges = useSelector((state) => state.trustBadge.list);
  const [active, setActive] = useState(false);
  const [trustBadgeIndex, setTrustBadgeIndex] = useState(0);

  useEffect(() => {
    if (shopId) {
      dispatch(getTrustBadge(shopId)).then((res) => setLoading(false));
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setActive(false);
  }, []);

  const handleActiveModal = useCallback(
    (index) => () => {
      setActive(true);
      setTrustBadgeIndex(index);
    },
    []
  );

  const rows = trustBadges.map(({ condition, icons, name, position_type, status }, index) => {
    return {
      preview: (
        <div style={{ width: "100px", height: "30px", display: "flex", alignContent: "center" }}>
          <Stack direction="row" spacing={2} alignItems="center">
            {icons.map((icon, index) => (
              <img key={index} src={icon?.image} style={{ height: "30px" }} />
            ))}
          </Stack>
        </div>
      ),
      label: (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>{name}</Typography>
        </Stack>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={status ? "active" : "deactivate"}
            color={status ? "success" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      positionType: (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>{position_type}</Typography>
        </Stack>
      ),
      startDate: <Typography>{condition.starts_at}</Typography>,
      endDate: <Typography>{condition.ends_at}</Typography>,
      action: (
        <IconButton color="info" onClick={handleActiveModal(index)}>
          <Visibility />
        </IconButton>
      ),
    };
  });
  return (
    <Card sx={{ marginTop: 3 }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Typography variant="h6" color="whitesmoke">
          Trust Badges
        </Typography>
      </MDBox>
      <MDBox pt={3}>
        {loading ? (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="info" />
          </Box>
        ) : trustBadges.length === 0 ? (
          <Typography>{`Don't have any label`}</Typography>
        ) : (
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          ></DataTable>
        )}
      </MDBox>
      <ModaltrustBadge active={active} onClose={handleCloseModal} dataIndex={trustBadgeIndex} />
    </Card>
  );
};

export default TabTrustBadge;
