import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ShopService from "service/ShopService";

export const getShopInfo = createAsyncThunk("getShopInfo", async (shopName) => {
  const response = await ShopService.getShopInfo(shopName);
  return response.data;
});

export const shopSlice = createSlice({
  name: "shop",
  initialState: {
    shopId: 0,
    shopDomain: "",
    themeAppStatus: false,
    plan: "Growth",
    trialDay: 3,
    install: false,
    planType: "Annual",
    error: false,
  },
  reducers: {
    resetError: (state) => {
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
    },
    logoutShop: (state) => {
      state.shopId = 0;
      state.error = false;
      state.shopDomain = "";
      state.themeAppStatus = false;
      state.plan = "Growth";
      state.trialDay = 3;
      state.install = false;
      state.planType = "Annual";
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShopInfo.fulfilled, (state, action) => {
      state.shopId = action.payload.data.shopId;
      state.shopDomain = action.payload.data.shopDomain;
      state.themeAppStatus = action.payload.data.themeAppStatus;
      state.plan = action.payload.data.plan;
      state.trialDay = action.payload.data.trialDay;
      state.install = action.payload.data.install;
      state.planType = action.payload.data.planType;
      state.error = false;
    }),
      builder.addCase(getShopInfo.rejected, (state, action) => {
        state.error = true;
      });
  },
});

export const { resetError, logoutShop, setError } = shopSlice.actions;

export default shopSlice.reducer;
