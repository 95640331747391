import { Box, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLabel } from "../../../../redux/labels";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

function ModalGroup({ active, onClose, dataIndex, ...rest }) {
  const groups = useSelector((state) => state.group.list);
  const labels = useSelector((state) => state.label.list);
  const shopId = useSelector((state) => state.shop.shopId);
  const [group, setGroup] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    setGroup(groups[dataIndex]);
    console.log(group);
  }, [dataIndex, groups]);

  useEffect(() => {
    if (shopId) {
      dispatch(getLabel(shopId));
    }
  }, [labels]);

  useEffect(() => {
    console.log(labels);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [group]);

  const flexDirection = {
    "pl-flex-column": "Column",
    "pl-flex-row": "Row",
  };

  return (
    <Modal open={active} onClose={onClose}>
      <Box sx={style}>
        {loading ? (
          <Box>
            <CircularProgress color="info" />
          </Box>
        ) : (
          <>
            <Typography>{`Label: ${group?.name}`} </Typography>
            <Box sx={{ marginTop: 2 }}>
              <Stack spacing={2}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2"> Position:</Typography>
                  <Typography variant="body2" color="tomato">
                    {group?.position}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2"> Position type:</Typography>
                  <Typography variant="body2" color="tomato">
                    {group?.position_unit_type}
                  </Typography>
                </Stack>
                {labels?.map((item, index) => {
                  return (
                    group?.label_ids?.includes(item.id) && (
                      <Stack direction="row" spacing={1} key={index}>
                        <Typography variant="body2"> Label {item.id}:</Typography>
                        <div
                          style={{
                            width: "100px",
                            height: "30px",
                            display: "flex",
                            alignContent: "center",
                          }}
                        >
                          <img src={item?.image} width="auto" height={30} />
                          {item?.label_type === 1 && (
                            <div
                              className={`${
                                item?.label_type === 1 && !item.image ? item.design.shape_class : ""
                              }`}
                              style={{
                                width: "30px",
                                height: "100%",
                                backgroundColor: !item.image && item.design.shape_color,
                              }}
                            ></div>
                          )}
                        </div>
                        <Stack direction="row" spacing={2} alignItems="center">
                          {item?.label_type === 1 && (
                            <Typography variant="caption">{item?.text}</Typography>
                          )}
                        </Stack>
                      </Stack>
                    )
                  );
                })}
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2"> Direction:</Typography>
                  <Typography variant="body2" color="tomato">
                    {flexDirection[group?.flex_direction]}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2"> Horizontal:</Typography>
                  <Typography variant="body2" color="tomato">
                    {group?.horizontal}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2"> Vertical:</Typography>
                  <Typography variant="body2" color="tomato">
                    {group?.vertical}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

ModalGroup.defaultProps = {
  active: true,
  onClose: () => {},
  dataIndex: 0,
};

ModalGroup.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataIndex: PropTypes.number,
};

export default ModalGroup;
