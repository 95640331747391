import { Box, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

function ModalBanner({ active, onClose, dataIndex, ...rest }) {
  const banners = useSelector((state) => state.banner.list);
  const [banner, setBanner] = useState({});
  const [condition, setCondition] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setBanner(banners[dataIndex]);
    setCondition(banners[dataIndex]?.condition);
  }, [dataIndex, banners]);

  useEffect(() => {
    setLoading(false);
  }, [banner]);

  useEffect(() => {
    console.log(banner);
  }, []);

  const conditionApply = {
    "specific-product": "Specific product",
    "all-product": "All product",
    collection: "From collection",
    tag: "With tag",
  };

  const bannerType = {
    normal: "fixed",
    slide: "slide",
    running: "running",
  };

  return (
    <Modal open={active} onClose={onClose}>
      <Box sx={style}>
        {loading ? (
          <Box>
            <CircularProgress color="info" />
          </Box>
        ) : (
          <>
            <Typography>{`Label: ${banner?.name}`} </Typography>
            <Box sx={{ marginTop: 2 }}>
              <Stack spacing={2}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2"> Position:</Typography>
                  {banner?.customize_position ? (
                    <></>
                  ) : (
                    <Typography variant="body2" color="tomato">
                      {banner?.position}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Page:</Typography>
                  {condition?.page?.map((item, index) => {
                    return (
                      <MDBadge
                        key={index}
                        badgeContent={item}
                        color="success"
                        variant="gradient"
                        size="sm"
                      />
                    );
                  })}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Condition:</Typography>
                  <Typography variant="body2" color="tomato">
                    {conditionApply[banner?.condition?.product_apply]}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Banner type:</Typography>
                  <Typography variant="body2" color="tomato">
                    {bannerType[banner?.type]}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Banner text:</Typography>
                  <Typography variant="body2" color="tomato">
                    {bannerType[banner?.type]}
                  </Typography>
                </Stack>
                {banner?.slides.map((item, index) => {
                  return (
                    <Stack direction="row" spacing={1} key={index}>
                      <Typography variant="body2">Slide {index + 1}:</Typography>
                      <Stack>
                        {item.content && (
                          <Typography variant="body2" color="tomato">
                            {item.content}
                          </Typography>
                        )}
                        {item.button_text && (
                          <MDBadge
                            key={index}
                            badgeContent={item.button_text}
                            color="success"
                            variant="gradient"
                            size="sm"
                          />
                        )}
                        {item.link && (
                          <Typography variant="body2" color="tomato">
                            {item.link}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  );
                })}
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Sticky:</Typography>
                  <Typography variant="body2" color="tomato">
                    {banner?.sticky.toString()}
                  </Typography>
                </Stack>
                {condition?.collections?.length > 0 && (
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2">Collections:</Typography>
                    <Typography variant="body2" color="tomato">
                      {condition?.collections.toString()}
                    </Typography>
                  </Stack>
                )}
                {condition?.tags?.length > 0 && (
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2">Tags:</Typography>
                    <Typography variant="body2" color="tomato">
                      {condition?.from_tags.toString()}
                    </Typography>
                  </Stack>
                )}
                {condition?.include_variants?.length > 0 && (
                  <Stack>
                    <Typography variant="body2">Include variants:</Typography>
                    {condition?.include_variants?.map((item, index) => {
                      return (
                        <Typography variant="body2" color="tomato" key={index}>
                          {`- ${item.title}`}
                        </Typography>
                      );
                    })}
                  </Stack>
                )}
                {banner?.use_countdown == 1 &&
                  (banner?.countdown?.type == "specific" ? (
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2">Time countdown:</Typography>
                      <Typography variant="body2" color="tomato">
                        {banner?.countdown?.specific_time}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row" spacing={1}>
                      <Typography variant="body2">Fixed countdown:</Typography>
                      <Typography variant="body2" color="tomato">
                        {banner?.countdown?.fixed_time}
                      </Typography>
                    </Stack>
                  ))}
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

ModalBanner.defaultProps = {
  active: true,
  onClose: () => {},
  dataIndex: 0,
};

ModalBanner.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataIndex: PropTypes.number,
};

export default ModalBanner;
