import { UploadFile } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const MDUploadImage = ({ onChange }) => {
  const [ready, setReady] = React.useState(false);

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsDataURL(file);
    });
  };

  const handleUploadFiles = useCallback((event) => {
    const files = event.target.files;
    Promise.all(
      Array.from(files).map(async (file) => {
        const data = await convertFileToBase64(file);
        return {
          name: file.name,
          src: data,
          type: file.type,
          success: false,
          error: false,
          loading: true,
        };
      })
    )
      .then((urls) => {
        onChange(urls);
      })
      .catch((error) => {
        console.error(error);
      });
    // Array.from(files).forEach(async (file) => {
    //   const data = await convertFileToBase64(file);
    //   dataResult.push({ name: file.name, image: data, type: file.type });
    // });
  }, []);
  return (
    <Button
      size="small"
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<UploadFile />}
      sx={{ color: "wheat" }}
    >
      Upload files
      <VisuallyHiddenInput type="file" accept="image/*" onChange={handleUploadFiles} multiple />
    </Button>
  );
};

MDUploadImage.defaultProps = {
  onChange: () => {},
};

MDUploadImage.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default MDUploadImage;
