import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TrustBadgeService from "service/TrustBagdeService";

export const getTrustBadge = createAsyncThunk("getTrustBadge", async (shopId) => {
  const response = await TrustBadgeService.getAllTrustBadge(shopId);
  return response.data;
});

export const trustBadge = createSlice({
  name: "trustBadges",
  initialState: {
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTrustBadge.fulfilled, (state, action) => {
      state.list = action.payload.data;
    });
  },
});

export const {} = trustBadge.actions;

export default trustBadge.reducer;
