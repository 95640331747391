import https from "./https-common";

const getAllGroup = (shopId) => {
  return https.get(`/groups?shopId=${shopId}`);
};

const getGroupById = (id) => {
  return https.get(`/group/${id}`);
};

const GroupService = {
  getAllGroup,
  getGroupById,
};

export default GroupService;
