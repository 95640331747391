import { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import TabShopInfo from "./components/Tab/TabShopInfo";
import TabLabel from "./components/Tab/TabLabel";
import TabBadge from "./components/Tab/TabBadge";
import TabBanner from "./components/Tab/TabBanner";
import TabGroup from "./components/Tab/TabGroup";
import TabTrustBadge from "./components/Tab/TabTrustBadge";
import {
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  Box,
  IconButton,
  InputBase,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  BadgeRounded,
  Label,
  Layers,
  Logout,
  Search,
  StoreMallDirectory,
  ViewCarousel,
  ViewDay,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getShopInfo, resetError, logoutShop, setError } from "../../redux/shop";

import { checkShopifyDomain } from "../../Utils/Ultility";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Merchant = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [shopDomain, setShopDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const shop = useSelector((state) => state.shop);
  const dispatch = useDispatch();
  const inputRef = useRef([]);

  useEffect(() => {
    dispatch(resetError());
  }, []);
  useEffect(() => {
    console.log(shop);
  }, [shop]);

  const handleChangeTab = useCallback((event, newValue) => {
    setTabIndex(newValue);
  }, []);

  const handleKeyPressEnter = useCallback(
    (event) => {
      if (event.key === "Enter") {
        setLoading(true);
        dispatch(getShopInfo(shopDomain)).then((res) => setLoading(false));
        inputRef.current[1].blur();
      }
    },
    [shopDomain, inputRef]
  );

  const handleChangeShop = useCallback((event) => {
    if (checkShopifyDomain(event.target.value)) {
      setShopDomain(event.target.value);
      dispatch(resetError());
    } else {
      setShopDomain(event.target.value);
      dispatch(setError());
    }
  }, []);

  const handleSearch = useCallback(() => {
    setLoading(true);
    dispatch(getShopInfo(shopDomain)).then((res) => setLoading(false));
  }, [shopDomain]);

  const handleLogout = useCallback(() => {
    dispatch(logoutShop());
    setShopDomain("");
    inputRef.current[1].focus();
  }, [inputRef]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box marginTop={2}>
        <Card>
          <CardHeader></CardHeader>
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                fullWidth
                placeholder="Search shopify domain"
                value={shopDomain}
                onChange={handleChangeShop}
                onKeyDown={handleKeyPressEnter}
                inputRef={(ref) => (inputRef.current[1] = ref)}
                disabled={shop.shopDomain.length > 0}
                helperText={shop.error ? "Invalid shopify domain" : ""}
                error={shop.error}
                InputProps={
                  shop.shopDomain.length === 0 && {
                    endAdornment: (
                      <InputAdornment>
                        <IconButton color="info" onClick={handleSearch}>
                          {loading ? <CircularProgress size={20} color="info" /> : <Search />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                }
              />
              {/* {shop.shopDomain.length === 0 && (
                <IconButton color="info" onClick={handleSearch}>
                  {loading ? <CircularProgress size={20} color="info" /> : <Search />}
                </IconButton>
              )} */}
              {shop.shopDomain.length > 0 && (
                <IconButton color="error" onClick={handleLogout}>
                  <Logout />
                </IconButton>
              )}
            </Box>
            <Box marginTop={2}>
              <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="merchant tab">
                <Tab icon={<StoreMallDirectory />} label="Shop Info" {...a11yProps(0)} />
                <Tab
                  icon={<Label />}
                  label="Label"
                  {...a11yProps(1)}
                  disabled={shop.shopDomain.length === 0}
                />
                <Tab
                  icon={<BadgeRounded />}
                  label="Badge"
                  {...a11yProps(2)}
                  disabled={shop.shopDomain.length === 0}
                />
                <Tab
                  icon={<Layers />}
                  label="Label bundle"
                  {...a11yProps(3)}
                  disabled={shop.shopDomain.length === 0}
                />
                <Tab
                  icon={<ViewCarousel />}
                  label="Banner"
                  {...a11yProps(4)}
                  disabled={shop.shopDomain.length === 0}
                />
                <Tab
                  icon={<ViewDay />}
                  label="Trust Badge"
                  {...a11yProps(5)}
                  disabled={shop.shopDomain.length === 0}
                />
              </Tabs>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <CustomTabPanel value={tabIndex} index={0}>
        <TabShopInfo />
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={1}>
        <TabLabel />
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={2}>
        <TabBadge />
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={3}>
        <TabGroup />
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={4}>
        <TabBanner />
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={5}>
        <TabTrustBadge />
      </CustomTabPanel>
    </DashboardLayout>
  );
};

export default Merchant;
