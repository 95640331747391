// ** Reducers Imports
import shop from "./shop";
import label from "./labels";
import banner from "./banner";
import badge from "./badge";
import trustBadge from "./trustBadge";
import group from "./group";

const rootReducer = { shop, label, banner, badge, trustBadge, group };

export default rootReducer;
