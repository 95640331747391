import { IconButton, Card, Typography, Stack, Avatar, Box, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import { Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import ModalLabelBundle from "../Modal/ModalGroup";
import { getGroup } from "../../../../redux/group";

const columns = [
  { Header: "label", accessor: "label", width: "15%", align: "left" },
  { Header: "status", accessor: "status", align: "center" },
  { Header: "action", accessor: "action", align: "center" },
];
const TabGroup = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const shopId = useSelector((state) => state.shop.shopId);
  const groups = useSelector((state) => state.group.list);
  const [active, setActive] = useState(false);
  const [groupIndex, setGroupIndex] = useState(0);

  useEffect(() => {
    dispatch(getGroup(shopId)).then((res) => setLoading(false));
  }, [shopId]);

  const handleCloseModal = useCallback(() => {
    setActive(false);
  }, []);

  const handleActiveModal = useCallback(
    (index) => () => {
      setActive(true);
      setGroupIndex(index);
    },
    []
  );

  const rows = groups.map(({ name, status }, index) => {
    return {
      label: (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>{name}</Typography>
        </Stack>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={status ? "active" : "deactivate"}
            color={status ? "success" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      action: (
        <IconButton color="info" onClick={handleActiveModal(index)}>
          <Visibility />
        </IconButton>
      ),
    };
  });
  return (
    <Card sx={{ marginTop: 3 }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <Typography variant="h6" color="whitesmoke">
          Label Bundles
        </Typography>
      </MDBox>
      <MDBox pt={3}>
        {loading ? (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress color="info" />
          </Box>
        ) : groups.length === 0 ? (
          <Typography>{`Don't have any Label bundle `}</Typography>
        ) : (
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          ></DataTable>
        )}
      </MDBox>
      <ModalLabelBundle active={active} onClose={handleCloseModal} dataIndex={groupIndex} />
    </Card>
  );
};

export default TabGroup;
