import https from "./https-common";

const getShopInfo = (shopName) => {
  let shop = shopName.replace(".myshopify.com", "");
  shop = shop.replace("https://", "");
  return https.get(`/shopInfo?${shopName ? `shop=${shop}` : ``}`);
};

const ShopService = {
  getShopInfo,
};

export default ShopService;
