import PropTypes, { bool } from "prop-types";
import { styled } from "@mui/material/styles";
import { CircularProgress, Icon, IconButton, Stack } from "@mui/material";
import { Check, Error, Refresh } from "@mui/icons-material";

const DivBlock = styled("div")({
  position: "relative",
  borderRadius: "5px",
  border: "1px solid #ccc",
  aspectRatio: "1",
});

const DivAbsolute = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  color: "white",
  fontSize: "1.5rem",
});

const MDImageUpload = ({ success, error, image, loading }) => {
  return (
    <DivBlock>
      <DivAbsolute>
        {loading && <CircularProgress />}
        {success && <Check color="success" fontSize="large" />}
        {error && (
          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Error color="error" fontSize="large" />
            <IconButton color="primary" size="large">
              <Refresh />
            </IconButton>
          </Stack>
        )}
      </DivAbsolute>
      <img className="MuiImageListItem-img" src={image?.src} alt={image?.name} loading="lazy" />
    </DivBlock>
  );
};

MDImageUpload.defaultProps = {
  success: false,
  error: false,
  image: { name: "", src: "" },
  loading: false,
};

MDImageUpload.propTypes = {
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  image: PropTypes.object,
  loading: PropTypes.bool,
};

export default MDImageUpload;
