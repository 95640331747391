import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  ImageList,
  ImageListItem,
  Stack,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useCallback, useEffect, useState } from "react";
import ModalUploadImage from "./components/ModalUploadImage";
import MDUploadImage from "components/MDUploadImage";
import MDImageUpload from "components/MDImageUpload";
import { useQueue } from "@uidotdev/usehooks";

const Gallery = () => {
  const [activeUpload, setActiveUpload] = useState(false);
  const [listImage, setListImage] = useState([]);
  const [indexImage, setIndexImage] = useState(0);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    if (typeof listImage[indexImage - 1] !== "undefined") {
      sleep(2000).then(() => {
        setIndexImage((prev) => prev + 1);
        console.log("image", listImage[indexImage - 1]);
      });
    }
  }, [indexImage]);

  const handleToggleUpload = useCallback(() => {
    setActiveUpload((prev) => !prev);
  }, []);

  const handleUploadFiles = useCallback(
    (value) => {
      setListImage((prev) => [...prev, ...value]);
      if (listImage.length === 0) {
        setIndexImage(1);
      }
    },
    [listImage]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box marginTop={2}>
        <Card>
          <CardHeader></CardHeader>
          <CardContent>
            <Stack>
              <Stack direction="row" justifyContent="end">
                <MDUploadImage onChange={handleUploadFiles} />
              </Stack>
              <Divider />
              <ImageList cols={5} gap={20}>
                {listImage.map((item) => {
                  return (
                    <ImageListItem key={item.name}>
                      <MDImageUpload
                        image={item}
                        loading
                        success={item.success}
                        error={item.error}
                      />
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <ModalUploadImage open={activeUpload} onClose={handleToggleUpload} />
    </DashboardLayout>
  );
};

export default Gallery;
