import https from "./https-common";

const getAllTrustBadge = (shopId) => {
  return https.get(`/trust-badges?shopId=${shopId}`);
};

const getTrustBadgeBadgeById = (id) => {
  return https.get(`/trust-badges/${id}`);
};

const TrustBadgeService = {
  getAllTrustBadge,
  getTrustBadgeBadgeById,
};

export default TrustBadgeService;
