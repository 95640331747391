//function check shopify domain
export const checkShopifyDomain = (url) => {
  const domainPath = url.split(".");
  if (domainPath.length === 1) return true;
  if (domainPath.length === 2) return false;
  if (domainPath.length === 3) {
    if (domainPath[1] === "myshopify" && domainPath[2] === "com") return true;
    return false;
  }
  return false;
};
