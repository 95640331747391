import { Box, Button, Card, CardContent, CardHeader, Modal, Stack } from "@mui/material";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  maxHeight: "80%",
  borderRadius: "10px",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

const ModalUploadImage = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Card sx={style}>
        <CardHeader></CardHeader>
        <CardContent>
          <Stack>
            <Stack justifyContent="end">
              <div></div>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

ModalUploadImage.defaultProps = {
  open: true,
  onClose: () => {},
};

ModalUploadImage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalUploadImage;
