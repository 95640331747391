import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GroupService from "service/GroupService";

export const getGroup = createAsyncThunk("getGroup", async (shopId) => {
  const response = await GroupService.getAllGroup(shopId);
  return response.data;
});

export const groupSlice = createSlice({
  name: "group",
  initialState: {
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGroup.fulfilled, (state, action) => {
      state.list = action.payload.data;
    });
  },
});

export const {} = groupSlice.actions;

export default groupSlice.reducer;
